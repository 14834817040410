import { useState } from "react";
import {
  HeroContainer,
  HeroBg,
  VideoBg,
  HeroContent,
  HeroH1,
  HeroP,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight,
} from "./Styles/HeroSection.styled";

import { Button } from "./Styles/Button.styled";

import Video from "../videos/loopvidPrimary.mp4";
// import Video from "../videos/powder.mp4";

const HeroSection = () => {
  const [hover, setHover] = useState(false);

  function onHover() {
    setHover(!hover);
  }

  return (
    <HeroContainer id="home">
      <HeroBg>
        <VideoBg autoPlay loop muted src={Video} type="video/mp4" />
      </HeroBg>
      <HeroContent>
        <HeroH1>A Deeper Dive</HeroH1>
        <HeroP>
          Explore what it means to be a person of color in the field of computer
          science and the feats achiveable with hard work and a pinch of luck.
        </HeroP>
        <HeroBtnWrapper>
          <Button
            to="contact"
            smooth={true}
            duration={500}
            spy={true}
            exact={"true"}
            offset={-80}
            primary={true}
            dark={false}
            onMouseEnter={onHover}
            onMouseLeave={onHover}
          >
            Reach Out {hover ? <ArrowForward /> : <ArrowRight />}
          </Button>
        </HeroBtnWrapper>
      </HeroContent>
    </HeroContainer>
  );
};

export default HeroSection;
