import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
} from "./Styles/InfoSection.styled";

import { ContactContainer } from "./Styles/Contact.styled";

function ContactForm() {
  const form = useRef();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [response, setResponse] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();

    if (name === "" || email === "" || message === "") {
      setResponse("Please fill out all the inputs ;)");
    } else {
      emailjs
        .sendForm(
          "service_zidg2la",
          "template_ekwv04m",
          form.current,
          "EVz6vG9cag2iq5Akj"
        )
        .then(
          (result) => {
            console.log(result.text);
            console.log("message sent!");
            setEmail("");
            setMessage("");
            setName("");
            setResponse("Message Sent!");
          },
          (error) => {
            console.log(error.text);
          }
        );
    }
  };

  return (
    <ContactContainer>
      <form ref={form} onSubmit={sendEmail}>
        <label>Name</label>
        <input
          type="text"
          name="user_name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          onClick={() => setResponse("")}
        />
        <label>Email</label>
        <input
          type="email"
          name="user_email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onClick={() => setResponse("")}
        />
        <label>Message</label>
        <textarea
          name="message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onClick={() => setResponse("")}
        />

        <input type="submit" value="Send" />

        <input type="response" value={response} readOnly />
      </form>
    </ContactContainer>
  );
}

export default function Contact({
  lightBg,
  id,
  imgStart,
  topLine,
  lightText,
  headLine,
  darkText,
  description,
  buttonLabel,
  img,
  alt,
  dark,
  primary,
  destination,
  addButton,
}) {
  return (
    <>
      <InfoContainer lightBg={lightBg} id={id}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <TopLine>{topLine}</TopLine>
                <Heading lightText={lightText}>{headLine}</Heading>
                <Subtitle darkText={darkText}>{description}</Subtitle>
              </TextWrapper>
            </Column1>
            <Column2>
              <ContactForm />
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
}
