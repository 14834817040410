import { useState } from "react";

import { ThemeProvider } from "styled-components";

import GlobalStyles from "../Components/Styles/Global";
import Navbar from "../Components/Navbar";
import Sidebar from "../Components/Sidebar";
import HeroSection from "../Components/HeroSection";
import InfoSection from "../Components/InfoSection";
import Footer from "../Components/Footer";
import { homeObjOne, homeObjTwo, homeObjFour } from "../Components/Data";
import Project from "../Components/Project";
import Contact from "../Components/Contact";

const theme = {
  colors: {
    header: "#ebfbff",
    body: "#fff",
    footer: "#003333",
    contrast: "#77018C",
  },
  dualProjects: "1000px",
  mobile: "768px",
  sidebar: "480px",
  phone: "400px",
};

function Home() {
  const [sidebarStatus, setSideBarStatus] = useState(false);

  function toggleSiderBar() {
    setSideBarStatus(!sidebarStatus);
  }

  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyles />
        <Sidebar isOpen={sidebarStatus} toggleSiderBar={toggleSiderBar} />
        <Navbar toggleSiderBar={toggleSiderBar} />
        <HeroSection />
        <InfoSection {...homeObjOne} />
        <InfoSection {...homeObjTwo} />
        {/* <InfoSection {...homeObjThree} /> */}
        <Project />

        <Contact {...homeObjFour} />
        <Footer />
      </>
    </ThemeProvider>
  );
}

export default Home;
