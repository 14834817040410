import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
  SideBtnWrap,
  SidebarRoute,
} from "./Styles/Sidebar.styled";

const Sidebar = ({ isOpen, toggleSiderBar }) => {
  return (
    <>
      <SidebarContainer isOpen={isOpen} onClick={toggleSiderBar}>
        <Icon onClick={toggleSiderBar}>
          <CloseIcon />
        </Icon>
        <SidebarWrapper>
          <SidebarMenu>
            <SidebarLink to="about" onClick={toggleSiderBar}>
              About
            </SidebarLink>
            <SidebarLink to="education" onClick={toggleSiderBar}>
              Education
            </SidebarLink>
            <SidebarLink to="projects" onClick={toggleSiderBar}>
              Projects
            </SidebarLink>
            <SidebarLink to="contact" onClick={toggleSiderBar}>
              Contact
            </SidebarLink>
          </SidebarMenu>
          <SideBtnWrap>
            <SidebarRoute
              to="contact"
              onClick={toggleSiderBar}
              smooth={true}
              duration={500}
              spy={true}
              exact={"true"}
              offset={-80}
            >
              Reach Out
            </SidebarRoute>
          </SideBtnWrap>
        </SidebarWrapper>
      </SidebarContainer>
    </>
  );
};

export default Sidebar;
