import React, { useState } from "react";
import { StyledSocialIcons } from "./Styles/SocialIcons.styled";

//do an array map method for the Cards, pass in the title, and screen shot,
//later make a popup, use the project thats clicked on to be a stat variable and based of tha state variable index into
//an array with that projects title as an index and reder that to the popup

import {
  ProjectsContainer,
  ProjectsH1,
  ProjectsWrapper,
  ProjectsCard,
  ProjectsH2,
  ProjectsIcon,
  ProjectsP,
  DualContainer,
  ProjectTextWrapper,
  ExpandedP,
  ProjectLink,
  SkillsContainer,
  SkillList,
  Skill,
} from "./Styles/Projects.styled";

import { homeObjThree } from "./Data";
import InfoSection from "./InfoSection";
import content from "./Content";
import { FaGithub } from "react-icons/fa";

const Project = () => {
  const [openCard, setOpenCard] = useState(0); // card id

  function ToggleCard(id) {
    if (openCard == null) {
      setOpenCard(id);
    } else if (openCard !== id) {
      setOpenCard(id);
    } else if (openCard === id) {
      setOpenCard(null);
    }
  }

  return (
    <DualContainer id="projects">
      <InfoSection {...homeObjThree} />
      <ProjectsContainer id="projects2">
        <ProjectsH1>My Projects</ProjectsH1>
        <ProjectsWrapper>
          {content.map((item, index) => (
            <Card
              openCard={openCard}
              ToggleCard={ToggleCard}
              key={index}
              item={item}
            />
          ))}
        </ProjectsWrapper>
      </ProjectsContainer>
    </DualContainer>
  );
};

export default Project;

function Card({
  openCard,
  ToggleCard,
  item: { id, title, body, image, extendedBody, skills, url },
}) {
  const cardActive = openCard === id;

  return (
    <ProjectsCard
      cardActive={cardActive}
      layout
      transition={{ duration: 0.5, type: "spring" }}
      onClick={() => ToggleCard(id)}
      order={id % 2 === 0 && "row-reverse"}
    >
      <ProjectsIcon
        layout="position"
        type="spring"
        // src="images/svg-2.svg"
        src={`images/${image}`}
        alt="project"
      />
      <ProjectTextWrapper>
        <ProjectsH2 layout="position">{title}</ProjectsH2>
        <SkillsContainer layout="position">
          <SkillList>
            {skills.map((skill) => (
              <Skill>{skill}</Skill>
            ))}
          </SkillList>
        </SkillsContainer>
        {!cardActive ? (
          <ProjectsP>{body}</ProjectsP>
        ) : (
          <ExpandedP
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 2 }}
          >
            <ProjectsP>{extendedBody}</ProjectsP>
            <ProjectLink>
              <StyledSocialIcons>
                <li>
                  <a
                    style={{ color: "#000", width: "100px", height: "100px" }}
                    href={url}
                  >
                    Github
                    <FaGithub />
                  </a>
                </li>
              </StyledSocialIcons>
            </ProjectLink>
          </ExpandedP>
        )}
      </ProjectTextWrapper>
    </ProjectsCard>
  );
}
