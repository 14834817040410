import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
   
    *{
        /* box-sizing: border-box; */
    }

    body { 
        background: ${({ theme }) => theme.colors.body};
        /* color:hsl(192, 100%, 9%); */
        font-family: 'Nova Square', sans-serif;
        font-size: 1.15em;
        margin:0;
        overflow-x: hidden;
    }

    p{
        /* opacity: 0.6; */
    }

    img{
        /* max-width: 100%; */
    }

`;

export default GlobalStyles;
