import styled from "styled-components";

export const ContactContainer = styled.div`
  max-width: 400px;
  min-width: 300px;

  /* height: 600px; */
  /* width: 100%; */
  margin-left: -50px;
  padding: 30px;

  form {
    /* background: ${({ theme }) => theme.colors.contrast}; */
    color: #000;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    font-size: 16px;
  }

  input {
    width: 100%;
    height: 35px;
    padding: 7px;
    outline: none;
    border-radius: 5px;
    border: 2px solid;
    margin: 5px 0;

    &:focus {
      border: 3px solid #77018c;
    }
  }

  textarea {
    max-width: 103%;
    min-width: 103%;
    max-height: 160px;
    min-height: 100px;
    border-radius: 5px;
    margin: 5px 0;

    border: 2px solid;

    &:focus {
      border: 3px solid #77018c;
    }
  }

  label {
    margin-top: 10px;
  }

  input[type="submit"] {
    margin-top: 2rem;
    cursor: pointer;
    background: #77018c;
    color: white;
    border: none;
    width: 105%;
  }

  input[type="response"] {
    text-align: center;
    font-size: 18px;
    margin-top: 2rem;
    cursor: pointer;
    background: none;
    color: #77018c;
    border: none;
    width: 105%;
  }

  @media (max-width: ${({ theme }) => theme.sidebar}) {
    margin-top: -300px;
  }

  @media (max-width: ${({ theme }) => theme.phone}) {
    margin-top: -150px;
  }
`;

export const ResponseWrapper = styled.div`
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Response = styled.input`
  font-size: 16px;
  width: 100%;
  height: 35px;
  padding: 7px;
  outline: none;
  background: #77018c;
  margin: 5px 0;
`;
