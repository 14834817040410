import styled from "styled-components";
import { motion } from "framer-motion";

export const DualContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProjectsContainer = styled.div`
  /* height:800px; */
  margin-top: -150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #010606;
  padding-bottom: 100px;

  /* @media (max-width: ${({ theme }) => theme.phone}) {
    //different heights  but with flex it should stretch to fit
    padding-right: 30px;
  } */
`;

//grid 3 by x
// export const ProjectsWrapper = styled.div`
//   max-width: 1000px;
//   margin: 40px auto;
//   display: grid;
//   grid-template-columns: 1fr 1fr 1fr;
//   align-items: center;
//   grid-gap: 16px;
//   padding: 0 50px;

//   @media (max-width: ${({ theme }) => theme.dualProjects}) {
//     grid-template-columns: 1fr 1fr;
//   }

//   @media (max-width: ${({ theme }) => theme.mobile}) {
//     grid-template-columns: 1fr;
//     padding: 0 20px;
//   }
// `;

export const ProjectsWrapper = styled.div`
  max-width: 1200px;
  /* margin: 40px auto; */
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* grid-gap: 16px; */
  padding: 0 50px;
  /* @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: 0 80px;
  } */
`;

export const ProjectsCard = styled(motion.div)`
  /* max-height: 340px; */
  min-height: 300px;
  max-width: ${({ cardActive }) => (cardActive ? "1200px" : "800px")};
  /* width: ${({ cardActive }) => (!cardActive ? "800px" : "95%")}; */
  margin: 20px 0;
  background: #fff;

  /* background: transparent; */
  /* color: #fff; */

  box-shadow: 0 0 15px 15px rgba(50, 50, 50, 0.9);
  display: flex;
  flex-direction: ${({ cardActive, order }) =>
    cardActive ? "column" : order || "row"};
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;

  padding: 30px;
  transition: all 0.2s ease-in-out;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    flex-direction: column;
  }

  @media (max-width: ${({ theme }) => theme.phone}) {
    flex-direction: column;
    max-width: 300px;
  }

  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor: ${({ cardActive }) => (cardActive ? "default" : "pointer")};
    box-shadow: 0 0 25px 5px rgba(119, 1, 140, 1);
  }
`;

// export const ProjectsIcon = styled.img`
//   height: 160px;
//   width: 160px;
//   margin-bottom: 10px;
// `;

export const ProjectsIcon = styled(motion.img)`
  border-radius: 5px;
  max-height: 340px;
  /* max-width: 50%; */
  max-width: ${({ cardActive }) => (!cardActive ? "50%" : "90%")};
  margin-bottom: 10px;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    max-height: 250px;
    max-width: 250px;
  }
`;

export const ProjectTextWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    margin-top: 20px;
  }
`;

export const ProjectsH1 = styled(motion.h1)`
  font-size: 2.5rem;
  color: ${({ theme }) => theme.colors.contrast};
  margin-bottom: 64px;

  @media (max-width: ${({ theme }) => theme.sidebar}) {
    font-size: 2rem;
  }
`;

export const ProjectsH2 = styled(motion.h2)`
  font-size: 1.5rem;
  margin-bottom: 10px;
  text-align: center;
`;

export const ProjectsP = styled(motion.p)`
  font-size: 1rem;
  text-align: center;
  /* @media (max-width: ${({ theme }) => theme.phone}) {
    font-size: 0.6rem;
  } */
`;

export const ExpandedP = styled(motion.div)`
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 30px;

  justify-content: Center;
`;

export const ProjectLink = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    color: ${({ theme }) => theme.colors.contrast};
  }
`;

export const SkillsContainer = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.contrast};
`;

export const SkillList = styled(motion.ul)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -40px;
`;

export const Skill = styled(motion.li)`
  padding: 0 5px;

  list-style-type: none;
  text-decoration: none;
`;
