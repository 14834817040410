import SocialIcons from "./SocialIcons";
import { Container } from "./Styles/Container.styled";
import { Flex } from "./Styles/Flex.styled";
import { StyledFooter } from "./Styles/Footer.styled";

export default function Footer() {
  return (
    <StyledFooter id="footer">
      <Container>
        {/* email api goes here     */}

        <Flex>
          <ul>
            <li>
              You can reach out to me at this phone number and email adress.
            </li>
            <li>You can also access my Github, LinkedIn and Instagram here.</li>
            <li>+1-403-402-8775</li>
            <li>davidog0b100@gmail.com</li>
          </ul>
          <SocialIcons />
        </Flex>
      </Container>
    </StyledFooter>
  );
}
