export const homeObjOne = {
  id: "education",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Education",
  headLine: "University of Calgary",
  description: `Currently pursuing a Bachelor of Science in Computer Science at the University of Calgary. 
    I am a proud award recipeint of the Presedential Admission Scholarship and The Computer Science Alumni Chapter Undergraduate Scholarship.
    I have completed various integral courses in Data Structures, Algorithm Analysis, Database Management, Operating Systems and more.  
    `,
  buttonLabel: "Find out more",
  imgStart: true,
  //   img: require("../images/svg-1.svg"),
  img: "images/uofc7.png",
  //   img: "images/svg-1.svg",
  alt: "Crest",
  dark: false,
  primary: true,
  darkText: false,
  destination: "footer",
};

export const homeObjTwo = {
  id: "about",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "About",
  headLine: "A Short Intro",
  description: `Originating from Nigeria, I have spent the majority of my life in Canada, and I currently reside in Calgary, AB. I'm an aspiring Full Stack Developer, working with frontend frameworks like  React.js and Backend Frameworks like Flask.
    I also have expereience with programing in other languanges, such as  Java, Python, C++, Haskel and  Prolog to name a few. Fun Fact, 
    I also run my own grooming business in NW Calgary, you can check the link to my instagram page below!
    `,
  buttonLabel: "Hit me up!",
  imgStart: false,
  //   img: require("../images/svg-1.svg"),
  img: "images/formal.jpeg",
  //   img: "images/svg-1.svg",
  alt: "Crest",
  dark: false,
  primary: true,
  darkText: true,
  destination: "contact",
  addButton: true,
};

export const homeObjThree = {
  id: "",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Projects",
  headLine: "Some things I've worked on",
  description: `These projects represent a selection of my completed work, achieved through independent efforts as well as collaborative teamwork.
    I'm currently working on an appointment booking app for my grooming business as well, so stay tuned!`,
  buttonLabel: "Take a Look",
  imgStart: true,
  img: "images/svg-1.svg",
  alt: "Crest",
  dark: false,
  primary: true,
  darkText: false,
  destination: "projects2",
  addButton: false,
};

export const homeObjFour = {
  id: "contact",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Contact",
  headLine: "Reach Out Below",
  description:
    "You can contact me directly through this site and you can get in touch with me by email or phone using the details provided below. Looking forward to hearing from you!",
  buttonLabel: "Contact Me",
  imgStart: false,
  //   img: require("../images/svg-1.svg"),

  img: "images/svg-2.svg",
  alt: "Crest",
  dark: false,
  primary: true,
  darkText: true,
  destination: "home",
  addButton: false,
};
