import {
  StyledNavbar,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavBtn,
  NavBtnLink,
} from "./Styles/Navbar.styled";

import { animateScroll as scroll } from "react-scroll";

import { useEffect, useState } from "react";

import { FaBars } from "react-icons/fa";

export default function Navbar({ toggleSiderBar }) {
  const [scrollNav, setScrollNav] = useState(false);

  function toggleHome() {
    scroll.scrollToTop();
  }

  function changeNav() {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);

  return (
    <>
      <StyledNavbar scrollNav={scrollNav}>
        <NavbarContainer>
          <NavLogo to="/" onClick={toggleHome}>
            David.George
          </NavLogo>
          <MobileIcon onClick={toggleSiderBar}>
            <FaBars />
          </MobileIcon>
          <NavMenu>
            <NavItem>
              <NavLinks
                to="education"
                smooth={true}
                duration={500}
                spy={true}
                exact={"true"}
                offset={-80}
              >
                Education
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to="about"
                smooth={true}
                duration={500}
                spy={true}
                exact={"true"}
                offset={-80}
              >
                About
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to="projects"
                smooth={true}
                duration={500}
                spy={true}
                exact={"true"}
                offset={-80}
              >
                Projects
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to="contact"
                smooth={true}
                duration={500}
                spy={true}
                exact={"true"}
                offset={-80}
              >
                Contact
              </NavLinks>
            </NavItem>
          </NavMenu>
          <NavBtn>
            <NavBtnLink
              to="contact"
              smooth={true}
              duration={500}
              spy={true}
              exact={"true"}
              offset={-80}
            >
              Reach Out
            </NavBtnLink>
          </NavBtn>
        </NavbarContainer>
      </StyledNavbar>
    </>
  );
}
