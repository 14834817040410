const content = [
  {
    id: 1,
    title: "This Portfolo Page!",
    body: "A bright webpage created with React.js, designed to reach out to others and showcase some of my skills as a Developer",
    image: "Portfolio.png",
    extendedBody: `This application was crafted using React.js and Styled Components alongside a selection of additional libraries. The scroll effect triggered by clicking a link was implemented using React-Scroll.
    I recognized this project as an excellent chance to gain experience in animation and inject a vibrant feel. To achieve this, I integrated the Framer-Motion library. Leveraging the provided documentation, 
    I successfully animated the section you're currently viewing. Working with Styled Components was an aspect I eagerly anticipated. While it required some adjustment, it significantly streamlines development, 
    allowing for customized components tailored to specific requirements—far more organized than conventional Tailwind CSS. An aspect that particularly appealed to me was Styled Components' accessibility to PROPS! 
    They remain components, which means they can dynamically adapt styles while harmonizing with state variables. I'm certainly committed to employing Styled Components moving forward!`,
    skills: ["React.js"],
    url: "https://github.com/DaveOGQ",
  },

  {
    id: 2,
    title: "FoodBank Management Software",
    body: "Full Stack Application created in response to a lack of an offcial management software at the University of Calgary Foodbank",
    image: "FoodBank.png",
    extendedBody: `This Foodbank management application is capable of creating new accounts, and allowing for admin and client side interactions. Admin are able to create new admin accounts, manage inventory, make orders, verify hamper requests as well as verify new users. Users on the other hand are able to signup, Login, view inventory and make hamper requests. 
    The initial idea for this project came about when I found out that the Foodbank on campus did not have a software system and that corespondence is done primarily through email and phone. This project was geared towards solving this problem, using React.js and Flask in tandem with MySQL. 
    I was the sole backend developer for the project and was tasked with designing the SQL database, setting up entity relationships, primary and foreign keys and more, which I achieved using a tool called MySQL Workbench. I also utilized MySQL Workbench to write and test queries used in the application. Flask utilizes URL routing by mapping specific URLs to functions within our application using decorators like @app.route('/'). 
    This allowed me to create different routes and handle HTTP requests such as GET, POST, PUT, and DELETE. Using Flask, I was able to design a class based system for making queries to send and retrieve data from the Sql database. Flask is adept at building RESTful APIs, where routes correspond to actions like retrieving all tasks or specific task details. 
    For data exchange, Flask utilizes JSON (JavaScript Object Notation), converting data to JSON format using jsonify( ) for sending data as HTTP responses and parsing incoming JSON data using request.json.You can find a complete writeup and manual for this project using the link below.`,
    skills: ["React.js", "Styled-Components", "Python", "Flask", "MySql"],
    url: "https://github.com/DaveOGQ/FoodBankManagementSystem",
  },

  {
    id: 3,
    title: "Fitness Tracker",
    body: "This is a Fitness Application designed to streamline scheduling exercises, tracking nutrition and tracking workouts to promote progressive overload training",
    image: "FitnessTracker.png",
    extendedBody: `This application represents my initial experience in actively applying my knowledge of React.js.  Now at this point I was still quite new to Tailwind CSS and the visual design can be revamped, however the project made up for that in functionality. 
    I was tasked with creating the Workouts and Stats landing page which would help users to track their progress in the gym. Users can view their workouts for any given date, and add, edit and delete exercises. User can also click on each exercise, and their saved data is set as default for all input fields. 
    They can edit this data and save their changes. The workouts sectionis complete with error handling, for any incorrect input. A full and complete repot of this project can be found using the link below.`,
    skills: ["React.js", "Html", "TailwindCSS"],
    url: "https://github.com/DaveOGQ/fitness-tracker",
  },

  {
    id: 4,
    title: "Detect Primes",
    body: "A highly efficient, multi-threadded program, utilizing the C++ standard library, designed to verify prime numbers within a given list of integers",
    image: "DetectPrimes.png",
    extendedBody: `The program reads integers from stdin between 1 to 2^63 -1, and identifies prime numbers, outputting them to stdout. It's implemented with Pthreads, where the user-defined argument 'n' starts 'n' corresponding pthreads. These threads run in parallel, checking factors of each integer sequentially. 
    If a factor is found, all threads move to the next integer. If no factor is identified, signifying a prime number, it's stored in a std::vector for prime integers.
    This approach utilizes advanced synchronization techniques such as Atomic Flags and Barriers, ensuring seamless coordination for both concurrent and serialized execution.`,
    skills: ["C++"],
    url: "https://github.com/DaveOGQ/SingleAndMultithreadedProgramsinCPP/blob/main/detect-primes/detectPrimes.cpp",
  },

  {
    id: 5,
    title: "AI Tic Tac Toe",
    body: "A game of Tic Tac Toe featuring an AI opponent offering different difficulty levels ",
    image: "TicTacToe.png",
    extendedBody: `This Java application stands as a compact yet comprehensive graphical user interface (GUI) tailored specifically for the timeless game of Tic Tac Toe. It introduces an AI opponent, allowing users to select from various difficulty levels, enhancing the gaming experience based on their preferred challenge level. 
      This adaptable feature empowers players to choose between different AI skill sets, catering to both casual players seeking a leisurely match and those desiring a more formidable gaming challenge.
      Furthermore, the application provides users with the flexibility to engage in gameplay through the intuitive GUI or opt for a command-line experience via the powershell interface, ensuring accessibility across multiple user preferences and environments. Adding to its user-centric design, the program includes a hint feature, offering assistance to players during gameplay. 
      This functionality proves invaluable, providing subtle guidance and strategic suggestions to aid users in making optimal moves, thereby enhancing their overall gameplay experience and encouraging skill development in the game of Tic Tac Toe.`,
    skills: ["Java"],
    url: "https://github.com/DaveOGQ/TicTacToe",
  },

  {
    id: 6,
    title: "Fantasy Team Tracker",
    body: "A comprehensive tool enabling seamless management of fantasy basketball teams, offering functionalities to create, modify, and analyze team and player statistics",
    image: "NBA.png",
    extendedBody: `The NBA Fantasy Team Tracker is a robust tool designed for efficient management of fantasy basketball teams. It streamlines team and player management by allowing users to create, update, and modify team information, including names and win counts. 
    Detailed player profiles, encompassing essential statistical data like Points Per Game (PPG), Assists Per Game (APG), Rebounds Per Game (RPG), and Minutes Per Game (MPG), offer precise insights into individual player performances. 
    Additionally, the tracker provides comprehensive team details, showcasing team names, win records, and complete rosters for strategic assessment. 
    With functionalities enabling the saving and loading of teams from CSV or text files, alongside features like identifying the Most Valuable Player (MVP) and highlighting top-performing teams, this tool ensures an immersive and comprehensive experience in managing NBA fantasy teams.`,
    skills: ["Java", "SceneBuilder"],

    url: "https://github.com/DaveOGQ/nba-fantasy-team-tracker",
  },
];

export default content;
