import { FaGithub, FaLinkedin, FaInstagram } from "react-icons/fa";
import { StyledSocialIcons } from "./Styles/SocialIcons.styled";

export default function SocialIcons() {
  return (
    <StyledSocialIcons>
      <li>
        <a href="https://www.linkedin.com/in/david-oti-george/">
          <FaLinkedin />
        </a>
      </li>

      <li>
        <a href="https://github.com/DaveOGQ">
          <FaGithub />
        </a>
      </li>

      <li>
        <a href="https://www.instagram.com/og_blendz_/">
          <FaInstagram />
        </a>
      </li>
    </StyledSocialIcons>
  );
}
