import styled from "styled-components";
import { FaTimes } from "react-icons/fa";
import { Link as LinkS } from "react-scroll";
// import { Link as LinkR } from "react-router-dom";

export const SidebarContainer = styled.div`
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: #0d0d0d;
  display: grid;
  align-items: center;
  top: 0;
  left: 0;
  transition: 0.3s ease-in-out;
  opacity: ${({ isOpen }) =>
    isOpen ? "100%" : "0"}; // makes visible or invisible
  top: ${({ isOpen }) =>
    isOpen
      ? "0"
      : "-100%"}; //causes the menu to drop down from above ot to recede back up
`;

export const CloseIcon = styled(FaTimes)`
  color: ${({ theme }) => theme.colors.contrast};
`;

export const Icon = styled.div`
  position: absolute;
  top: 1.2rem;
  right: 1.5em;
  background: transparent;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
`;

export const SidebarWrapper = styled.div`
  color: #fff;
`;

export const SidebarMenu = styled.ul`
  margin-right: 3rem; //custom
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(6, 80px);
  text-align: center;

  @media (max-width: ${({ theme }) => theme.sidebar}) {
    grid-template-rows: repeat(6, 60px);
  }
`;

export const SidebarLink = styled(LinkS)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  text-decoration: none;
  list-style: none;
  transition: 0.2s ease-in-out;
  color: #fff;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.contrast};
    transition: 0.2s ease-in-out;
  }
`;

export const SideBtnWrap = styled.div`
  display: flex;
  justify-content: center;
`;

export const SidebarRoute = styled(LinkS)`
  border-radius: 50px;
  background: ${({ theme }) => theme.colors.contrast};
  white-space: nowrap;
  padding: 16px 64px;
  color: #fff;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    color: ${({ theme }) => theme.colors.contrast};
    background: #fff;
    transform: scale(0.95);
  }
`;
